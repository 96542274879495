<script>
import { required } from "vuelidate/lib/validators";
import validationMessages from '@/components/validations';
import Monitor from '@/services/Monitor';
import Swal from "sweetalert2";

export default {
  components: { validationMessages },
  props: ['socialMilestoneData'],
  data() {
    return {

      name: "",
      id: "",
      description: "",
      progress_goal: "",
      points_reward: "",
      season: "",
      showModal: false,
      submitted: false,
      tryingToSubmit: false,
      showLoader: false,
      csrf_token: localStorage.getItem('csrf_token'),
      tryingToEdit: false,
    };
  },

  validations: {
    name: { required }
  },

  watch: {
    socialMilestoneData(newVal) {
      this.id = newVal.id;
      this.name = newVal.name;
      this.description = newVal.description;
      this.points_reward = newVal.points_reward;
      this.progress_goal = newVal.progress_goal;
      this.season = newVal.season;
    }
  },

  methods: {
    closeModal() {
      this.showModal = false;
      this.submitted = false;
      this.tryingToEdit = false;
    },

    async editSocialMilestone() {
      this.tryingToEdit = true;
      this.submitted = true;
      this.tryingToSubmit = true;

      this.$v.$touch();

      if (this.$v.$invalid) {
        this.tryingToEdit = false;
        return;
      } else {
        let milestoneData = {
          id: this.id,
          name: this.name,
          description: this.description,
          points_reward: this.points_reward,
          progress_goal: this.progress_goal,
          season: this.season ? this.season : null,
        };

        try {
          const response = await Monitor.editSocialMilestone(this.socialMilestoneData.id, milestoneData);
          const error = typeof response.error !== 'undefined' ? response.error : false;

          if (error !== '') {
            this.successmsg("Social Milestone successfully updated");
            this.closeModal();
          } else {
            this.failedmsg("Failed to update Social Milestone");
          }
        } catch (error) {
          this.error = error.response.data.error ? error.response.data.error : "";
          this.failedmsg(this.error);
        } finally {
          this.refreshData();
          this.tryingToSubmit = false;
          this.showLoader = false;
        }
      }
      this.tryingToSubmit = false;
    },


    refreshData() {
      this.$emit('onRefresh'); // Event to refresh data in parent component
    }
  }
};
</script>

<template>
  <b-modal id="edit__social_milestone" size="l" v-model="showModal" title="Edit Social Milestone" title-class="font-18">
    <form @submit.prevent="editSocialMilestone()">
      <div class="row" v-if="socialMilestoneData">
        <div v-if="$v.$error" class="text-danger">Complete all fields</div>
        <b-card-text>
          <div class="row">
            <h4 class="card-title m-0">Social Xp: {{ this.socialMilestoneData.id }}</h4>

            <div class="col-sm-12">
              <b-form-group label="Name" label-for="formrow-name-input" class="mb-3">
                <b-form-input v-model.trim="name" :class="{ 'is-invalid': submitted && $v.name.$error }"></b-form-input>
                <validationMessages v-if="submitted" :fieldName="'Name'" :validationName="$v.name"></validationMessages>
              </b-form-group>

              <b-form-group label="Description" label-for="formrow-description-input" class="mb-3">
                <b-form-input v-model.trim="description"></b-form-input>
              </b-form-group>

              <b-form-group label="Points Reward" label-for="formrow-points_reward-input" class="mb-3">
                <b-form-input v-model.trim="points_reward" type="number"></b-form-input>
              </b-form-group>

              <b-form-group label="Progress Goal" label-for="formrow-progress_goal-input" class="mb-3">
                <b-form-input v-model.trim="progress_goal" type="number"></b-form-input>
              </b-form-group>

              <div class="col-sm-12 col-md-4">
                <h5>Season</h5>
                <b-form-checkbox-group >
                  <div class="form-check form-switch form-switch-lg mb-3" >
                    <input v-model="season"
                           type="checkbox"
                           class="form-check-input"
                           id="switch1"
                           true-value="1"
                           false-value="0"
                    />
                  </div>
                </b-form-checkbox-group>
              </div>

              <input type="hidden" name="csrf_token" v-model="csrf_token">
            </div>
          </div>
        </b-card-text>
      </div>
    </form>

    <template v-slot:modal-footer>
      <b-button variant="light" @click="closeModal">Close</b-button>
      <b-button variant="primary" @click="editSocialMilestone" :disabled="tryingToSubmit || showLoader">
        <b-spinner v-show="tryingToSubmit" small></b-spinner>Submit
      </b-button>
    </template>
  </b-modal>
</template>
